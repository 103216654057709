import './bootstrap';

import * as Popper from "@popperjs/core"

require("bootstrap");
import * as mdb from 'mdb-ui-kit';

import $ from 'jquery';
window.$ = window.jQuery = $;

require('jquery-ui');
require('jquery-ui/ui/widgets/sortable');
require('jquery-ui/ui/disable-selection');
